/* eslint-disable import/namespace */
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  weekOptionList: [],
  weekdayList: [],
};

const attendanceConfigurSlice = createSlice({
  name: "attendanceConfigur",
  initialState,
  reducers: {
    setWeekOffList: (state, action) => {
      state.weekOptionList = [
        ...state.weekOptionList,
        ...action.payload.actionList,
      ];
      state.weekdayList = action.payload.weekdayList;
    },
    addWeekOffList: (state, action) => {
      state.weekOptionList = [
        ...state.weekOptionList,
        action.payload,
      ];
    },
    removeWeekOffList: (state, action) => {
      state.weekOptionList = state.weekOptionList?.filter(
        (item) => item.value  !== action.payload.value 
      );
    },
    addWeekDay: (state, action) => {
      state.weekdayList = [...new Set([...state.weekdayList, action.payload])];
    },
    removeWeekDay: (state, action) => {
      state.weekOptionList = state.weekOptionList.filter(
        (item) => item.id !== action.payload.id
      );
      state.weekdayList = state.weekdayList.filter(
        (item) => item.id !== action.payload.id
      );
    },
    clearSetStates: (state) => {
      state.weekOptionList = [];
      state.weekdayList = [];
    },
  },
});

export const {
  setWeekOffList,
  addWeekOffList,
  addWeekDay,
  removeWeekOffList,
  removeWeekDay,
  clearSetStates,
} = attendanceConfigurSlice.actions;

export default attendanceConfigurSlice.reducer;